import {React, useState, useEffect} from 'react';
import './modalShip.css';
import ModalUserSelect from './ModalUserSelect';
import ModalGatewaySelect from './ModalGatewaySelect';
import UseContextMenu from '../popupMenu/UseContextMenu';
import { BiX } from 'react-icons/bi';
import {BiSearch} from 'react-icons/bi'
import {ImCheckboxChecked, ImCheckboxUnchecked} from 'react-icons/im'
import { useSelector, useDispatch } from "react-redux";
import noImage from '../../icons/NoPicture.png';
import { setCurDevInfo } from '../../redux/modules/curDevInfo';
import { addShip } from '../../redux/modules/ships';
import { updateShip } from '../../redux/modules/ships';
import { updateDevice } from '../../redux/modules/devices';
import { updateGateway } from '../../redux/modules/gateways';
import { updateCurShip } from '../../redux/modules/curShip';
import {commRegisterShipDB, commUpdateShipDB, commDeleteShipDB, commUploadShipLicenseImage, commUpdateDeviceDB, commUpdateGatewayDB} from '../comm/CommUpdateDB';
import { addShipToAdd } from '../../redux/modules/shipsToAdd';
import { MyAlert } from '../popupMenu/Confirm';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
var isSearchMode = false;
var devicesNotRegistered;

const ModalShip = (props) => {
  const { open, close } = props;
  const [modalGatewayOpen, setModalGatewayOpen] = useState(false);
  const [modalUser1Open, setModalUser1Open] = useState(false);
  const [modalUser2Open, setModalUser2Open] = useState(false);
  const ships = useSelector((state) => state.ships);
  const gateways = useSelector((state) => state.gateways);
  const devices = useSelector((state) => state.devices);  
  const users = useSelector((state) => state.users);
  const curShip = useSelector((state) => state.curShip);
  const curDevices = useSelector((state) => state.curDevices);
  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);
  const [list2Backup, setList2Backup] = useState([]);
  const dispatch = useDispatch();
  const [imgFile1, setImgFile1] = useState("");
  const [imgFile1Name, setImgFile1Name] = useState("");
  const [imgFile2, setImgFile2] = useState("");
  const [imgFile2Name, setImgFile2Name] = useState("");
  const [textShipNo, setTextShipNo] = useState("");
  const [textShipName, setTextShipName] = useState("");
  const [textPort, setTextPort] = useState("");
  const [textGateway, setTextGateway] = useState("");
  const [textGatewayInstallDay, setTextGatewayInstallDay] = useState("");
  const [textOwnerID, setTextOwnerID] = useState("");
  const [textCaptainID, setTextCaptainID] = useState("");
  const [textOwnerName, setTextOwnerName] = useState("");
  const [textOwnerPhone, setTextOwnerPhone] = useState("");
  const [textOwnerAddress, setTextOwnerAddress] = useState("");
  const [textOwnerEmail, setTextOwnerEmail] = useState("");
  const [textCaptainName, setTextCaptainName] = useState("");
  const [textCaptainPhone, setTextCaptainPhone] = useState("");
  const [textCaptainEmail, setTextCaptainEmail] = useState("");
  const [searchContent, setSearchContent] = useState("");
  const [shipLicense1, setShipLicense1] = useState("");
  const [shipLicense2, setShipLicense2] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [imgSrc1, setImgSrc1] = useState("");
  const [imgSrc2, setImgSrc2] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertOpen1, setAlertOpen1] = useState(false);
  const [alertContent, setAlertContent] = useState("");
  const curDevInfo = useSelector((state) => state.curDevInfo);

  const setAlert = (content) => {
    setAlertContent(content);
    setAlertOpen(true);
  }

  const openLightbox = (index) => {
    setIsOpen(true);
    if(index === 1)
    {
      setImgSrc1(document.getElementById('img1').src);
      setImgSrc2(document.getElementById('img2').src);
    }
    else
    {
      setImgSrc1(document.getElementById('img2').src);
      setImgSrc2(document.getElementById('img1').src);
    }
  }
  
  useEffect(() => {
    //console.log("set initial values");
    //console.log("props.isRegister "+props.isRegister);
    if (props.open){
      console.log(devices);
      setImgFile1("");
      setImgFile2("");
      if (props.isRegister){
        setTextShipNo("");
        setTextShipName("");
        setTextPort("");
        setTextGateway("");
        setTextGatewayInstallDay("");
        setShipLicense1("");
        setShipLicense2("");
        setTextOwnerName("");
        setTextOwnerPhone("");
        setTextOwnerAddress("");
        setTextOwnerEmail("");
        setTextCaptainName("");
        setTextCaptainPhone("");
        setTextCaptainEmail("");   
        setSearchContent("");
        setList1([]);
        devicesNotRegistered = devices.filter(device => (device.shipNo === "" || device.shipNo === null));
        devicesNotRegistered.map(device => device.select = false);
        setList2(devicesNotRegistered);
      }
      else{
        setTextShipNo(curShip.shipNo);
        setTextShipName(curShip.shipName);
        setTextPort(curShip.port);
        setTextGateway(curShip.gatewayID.toUpperCase());
        setTextGatewayInstallDay(curShip.gatewayInstallDay);
        setShipLicense1(curShip.licensefile1);
        setShipLicense2(curShip.licensefile2);
        const owner = users.find(user => user.userid === curShip.ownerID);
        if (owner !== undefined){
          setTextOwnerName(owner.name);
          setTextOwnerPhone(owner.phone);
          setTextOwnerAddress(owner.address);
          setTextOwnerEmail(owner.email);
        }
        else{
          setTextOwnerName("");
          setTextOwnerPhone("");
          setTextOwnerAddress("");
          setTextOwnerEmail("");
        }
        const captain = users.find(user => user.userid === curShip.captainID);
        if (captain !== undefined){
          setTextCaptainName(captain.name);
          setTextCaptainPhone(captain.phone);
          setTextCaptainEmail(captain.email);
        }
        else{
          setTextCaptainName("");
          setTextCaptainPhone("");
          setTextCaptainEmail(""); 
        }  
        setSearchContent("");
        const devicesToSet = devices.filter(device => device.shipNo === curDevInfo.shipNo);
        setList1(devicesToSet.sort((a,b) => (b.no > a.no)? -1:1));
        devicesNotRegistered = devices.filter(device => (device.shipNo === "" || device.shipNo === null));
        devicesNotRegistered.map(device => device.select = false);
        setList2(devicesNotRegistered);
      }
    }
    
    //console.log("list2Backup");
    //console.log(list2Backup);

  }, [props.open]);

  const hadleImageUploadButton1 = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImgFile1(reader.result);
      setImgFile1Name(file);
    };    
  };
  const hadleImageUploadButton2 = e => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImgFile2(reader.result);
      setImgFile2Name(file);
    };
  };

  function makeDeviceNumber(list){
    //console.log(list);
    var max = 0;
    list.forEach(device => {
      if (device.no > max)
        max = device.no;
      //console.log(max);
    });
    return (max + 1);
  }

  function registerClicked(){
    const deviceToRegister = list2.filter(device => device.select === true);
    setList2(list2.filter(device => device.select === false));
    var listNew = list1;
    deviceToRegister.map(device => {
      device.select = false;
      device.state = 0x00;
      device.no = makeDeviceNumber(listNew);
      listNew = [...listNew, device];
    });
    setList1(listNew.sort((a,b) => (b.no > a.no)? -1:1));

    if (isSearchMode){
      listNew = list2Backup;
      deviceToRegister.map(device => {
        listNew = listNew.filter(item => item.devEui !== device.devEui);
      });
      setList2Backup(listNew);
    }
  };

  function removeClicked(){
    //console.log("removeClicked clicked");
    const deviceToRemove = list1.filter(device => device.select === true);
    setList1(list1.filter(device => device.select === false).sort((a,b) => (b.no > a.no)? -1:1));
    var listNew = list2;
    deviceToRemove.map(device => {
      device.select = false;
      listNew = [...listNew, device];
    });
    setList2(listNew);
    console.log(listNew);

    if (isSearchMode){
      listNew = list2Backup;
      deviceToRemove.map(device => {
        listNew = [...listNew, device];
      });
      setList2Backup(listNew);
    }
  };

  function okClicked(){
    const ownerID = textOwnerID? textOwnerID:curShip.ownerID;
    const captainID = textCaptainID? textCaptainID:curShip.captainID;
    
    if (props.isRegister){
      // 신규등록인 경우
      if (textShipNo === ""){
        setAlert("어선번호를 입력하지 않았습니다.");
        return;
      }
      if (textShipName === ""){
        setAlert("어선명을 입력하지 않았습니다.");
        return;
      }
      if (textPort === ""){
        setAlert("선적항을 입력하지 않았습니다.");
        return;
      }
      if (textGateway === ""){
        setAlert("게이트웨이 ID를 입력하지 않았습니다.");
        return;
      }
      if (textOwnerID === ""){
        setAlert("선주정보를 입력하지 않았습니다.");
        return;
      }
      if (textCaptainID === ""){
        setAlert("선장정보를 입력하지 않았습니다.");
        return;
      }
      if (ships.filter(ship => ship.shipNo === textShipNo).length > 0){
        setAlert("중복된 어선번호가 있습니다!");
        //setTextShipNo("");
        return;
      }      
      
      const ship = {
        shipNo: textShipNo, 
        shipName: textShipName, 
        port: textPort, 
        ownerID: ownerID, 
        captainID: captainID, 
        gatewayID: textGateway, 
        gatewayInstallDay: textGatewayInstallDay,
        lat: "", 
        lng: "", 
        state: '정상', 
        lastComm: "", 
        marker: null, 
        overlay: null, 
        select: false
      };
      dispatch(addShip(ship));
      dispatch(addShipToAdd(ship.shipNo));  //map에 등록
      //dispatch(setCurShip(ship));
      //const devInfo = {isShip: true, shipNo: ship.shipNo, devEui: null, fromSide: true};
      //dispatch(setCurDevInfo(devInfo));

      commRegisterShipDB(ship);

      const gateway = gateways.find(item => item.gatewayID === textGateway);
      if (gateway){
        dispatch(updateGateway(gateway.gatewayID, 'shipNo', textShipNo));

        const updateDB = {gatewayID: textGateway, shipNo: textShipNo};
        commUpdateGatewayDB(updateDB);
      }

      // device 추가
      list1.forEach(device => {
        dispatch(updateDevice(device.devEui, 'shipNo', ship.shipNo));
        dispatch(updateDevice(device.devEui, 'no', device.no));
        const updateDB = {devEui: device.devEui, shipNo: ship.shipNo, no: device.no};
        commUpdateDeviceDB(updateDB);
      });
      list2.forEach(device => {
        dispatch(updateDevice(device.devEui, 'shipNo', ""));
        const updateDB = {devEui: device.devEui, shipNo: ""};
        commUpdateDeviceDB(updateDB);
      });

        if(imgFile1)
        {
          commUploadShipLicenseImage(textShipNo, imgFile1Name, 1, /*curTime+*/'_1.'+imgFile1Name.name.split('.').pop());
          dispatch(updateShip(textShipNo, 'licensefile1', textShipNo+'_'+/*curTime+*/'_1.'+imgFile1Name.name.split('.').pop()))
          dispatch(updateCurShip(textShipNo, 'licensefile1', textShipNo+'_'+/*curTime+*/'_1.'+imgFile1Name.name.split('.').pop()))
        }
        if(imgFile2)
        {
          commUploadShipLicenseImage(textShipNo, imgFile2Name, 2, /*curTime+*/'_2.'+imgFile2Name.name.split('.').pop());
          dispatch(updateShip(textShipNo, 'licensefile2', textShipNo+'_'+/*curTime+*/'_2.'+imgFile2Name.name.split('.').pop()))
          dispatch(updateCurShip(textShipNo, 'licensefile2', textShipNo+'_'+/*curTime+*/'_2.'+imgFile2Name.name.split('.').pop()))
        }
        setAlertContent("어선등록이 완료되었습니다");
        setAlertOpen1(true);
    }
    else{
      // 기존어선 정보수정인 경우
        if (textShipNo !== curShip.shipNo){
          if (ships.filter(ship => ship.shipNo === textShipNo).length > 0){
            setAlert("중복된 어선번호가 있습니다!");
            return;
          }
        }
        dispatch(updateShip(curShip.shipNo, 'shipName', textShipName));
        dispatch(updateShip(curShip.shipNo, 'port', textPort));
        dispatch(updateShip(curShip.shipNo, 'gatewayID', textGateway));
        dispatch(updateShip(curShip.shipNo, 'gatewayInstallDay', textGatewayInstallDay));
        dispatch(updateShip(curShip.shipNo, 'ownerID', ownerID));
        dispatch(updateShip(curShip.shipNo, 'captainID', captainID));
        dispatch(updateShip(curShip.shipNo, 'shipNo', textShipNo));

        dispatch(updateCurShip('shipNo', textShipNo));
        dispatch(updateCurShip('shipName', textShipName));
        dispatch(updateCurShip('port', textPort));
        dispatch(updateCurShip('gatewayID', textGateway));
        dispatch(updateCurShip('gatewayInstallDay', textGatewayInstallDay));
        dispatch(updateCurShip('ownerID', ownerID));
        dispatch(updateCurShip('captainID', captainID));

        const devInfo = {isShip: true, shipNo: textShipNo, devEui: null, fromSide: true};
        dispatch(setCurDevInfo(devInfo));

        const updateData = { shipNo: curShip.shipNo, newShipNo: textShipNo, shipName: textShipName, port: textPort, 
          gatewayID: textGateway, gatewayInstallDay: textGatewayInstallDay, ownerID: ownerID, captainID: captainID };
        commUpdateShipDB(updateData);

        const gateway = gateways.find(item => item.gatewayID === textGateway);
        if (gateway){
          dispatch(updateGateway(gateway.gatewayID, 'shipNo', textShipNo));

          const updateDB = {gatewayID: textGateway, shipNo: textShipNo};
          commUpdateGatewayDB(updateDB);
        }

        // device 업데이트
        list1.forEach(device => {
          dispatch(updateDevice(device.devEui, 'shipNo', textShipNo));
          dispatch(updateDevice(device.devEui, 'no', device.no));
          const updateDB = {devEui: device.devEui, shipNo: textShipNo, no: device.no};
          commUpdateDeviceDB(updateDB);
        });
        list2.forEach(device => {
          dispatch(updateDevice(device.devEui, 'shipNo', ""));
          const updateDB = {devEui: device.devEui, shipNo: ""};
          commUpdateDeviceDB(updateDB);
        });

        if(imgFile1)
        {
          commUploadShipLicenseImage(textShipNo, imgFile1Name, 1, /*curTime+*/'_1.'+imgFile1Name.name.split('.').pop());
          dispatch(updateShip(textShipNo, 'licensefile1', textShipNo+'_'+/*curTime+*/'_1.'+imgFile1Name.name.split('.').pop()))
          dispatch(updateCurShip(textShipNo, 'licensefile1', textShipNo+'_'+/*curTime+*/'_1.'+imgFile1Name.name.split('.').pop()))
        }
        if(imgFile2)
        {
          commUploadShipLicenseImage(textShipNo, imgFile2Name, 2, /*curTime+*/'_2.'+imgFile2Name.name.split('.').pop());
          dispatch(updateShip(textShipNo, 'licensefile2', textShipNo+'_'+/*curTime+*/'_2.'+imgFile2Name.name.split('.').pop()))
          dispatch(updateCurShip(textShipNo, 'licensefile2', textShipNo+'_'+/*curTime+*/'_2.'+imgFile2Name.name.split('.').pop()))
        }
        setAlertContent("어선기본정보가 수정되었습니다");
        setAlertOpen1(true);
    }
  };

  /*useEffect(() => {
    console.log(curShip);
    console.log(ships);
  }, [ships, curShip]);*/

  function cancelClicked(){
    close();
  };
  
  function clickSelect1(devEui){
    const newList = list1.map((device) =>
      device.devEui === devEui? {...device, select: !device.select} : device);
    setList1(newList);
  }
  function clickSelect2(devEui){
    const newList = list2.map((device) =>
      device.devEui === devEui? {...device, select: !device.select} : device);
    setList2(newList);
  }

  const onChange1 = (e) => {
    setTextShipNo(e.target.value);
  };
  const onChange2 = (e) => {
    setTextShipName(e.target.value);
  };
  const onChange3 = (e) => {
    setTextPort(e.target.value);
  };
  const onChange4 = (e) => {
    setSearchContent(e.target.value.toUpperCase());
  };

  const nunmberChanged = (deviceChanged, e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '');
  
    var newValueInt;
    if (newValue === ''){
      newValueInt = -1;
    }
    else if (newValue === '0'){
      newValueInt = 0;
    }
    else{
      newValueInt = parseInt(newValue);
    }
    
    const listNew = list1.map((device) => (
      device.devEui === deviceChanged.devEui? {...device, no: newValueInt} : device
    ));
    setList1(listNew);
  }

  function search(){
    //setList2Backup(list2);
    var list = devicesNotRegistered;
    list1.forEach(item => {
      list = list.filter(item1 => item1.devEui !== item.devEui);
    })
    setList2Backup(list);

    console.log("setList2Backup");
    console.log(list);

    setList2(list.filter(device => 
      device.devEui.toUpperCase().includes(searchContent.toUpperCase())));
    isSearchMode = true;
  };
  function searchReset(){
    setSearchContent("");
    setList2(list2Backup);
    isSearchMode = false;
  }

  function setGateway(gateway){
    setTextGateway(gateway.gatewayID);
    var today = new Date();
    var day = String(today.getFullYear()).padStart(4,"0") + '.' + String(today.getMonth() + 1).padStart(2,"0")+ '.' + String(today.getDate()).padStart(2,"0");
    setTextGatewayInstallDay(day);
  }
  function setOwner(user){
    setTextOwnerID(user.userid);
    setTextOwnerName(user.name);
    setTextOwnerEmail(user.email);
    setTextOwnerPhone(user.phone);
    setTextOwnerAddress(user.address);
  }
  function setCaptain(user){
    setTextCaptainID(user.userid);
    setTextCaptainName(user.name);
    setTextCaptainEmail(user.email);
    setTextCaptainPhone(user.phone);
  }

  function idOverlapCheck(){
    if (ships.filter(ship => ship.shipNo === textShipNo).length > 0){
      setAlert("중복된 어선번호가 있습니다!");
      //setTextShipNo("");
    }
    else{
      setAlert("중복된 어선번호가 없습니다.");
    }
  }
  
  const handleOnKeyPress1 = e => {
    console.log("handleOnKeyPress1");
    if (e.key === 'Enter') {
      idOverlapCheck(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };
  const handleOnKeyPress2 = e => {
    console.log("handleOnKeyPress2");
    if (e.key === 'Enter') {
      search(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  function selectAllClicked1(){
    if (list1.length < 1)
      return;    
    const newValue = !list1[0].select;
    
    const newList = list1.map((device) => {
      return  {...device, select: newValue}
      });
    setList1(newList.sort((a,b) => (b.no > a.no)? -1:1));
  }
  function selectAllClicked2(){
    if (list2.length < 1)
      return;    
    const newValue = !list2[0].select;
    
    const newList = list2.map((device) => {
      return  {...device, select: newValue}
      });
    setList2(newList);
  }


  return (
    <div className={open ? 'openModal modal' : 'modal'}>
      {open ? (
        <div className='modalShip'>
          <div className='modalShip_title'>
              {props.isRegister? "어선등록":"어선 기본정보수정"}
              <BiX className='modalShip_button1' onClick={close}></BiX>
          </div>
          <div className='modalShip_content'>
            <div className='modalShip_left'>
              <div className='modalShip_itemTitle'>기본정보</div>
              <div className='modalShip_line'></div>
              <div className='modalShip_item'>
                <div className='modalShip_item_left'>어선번호</div>
                <div className='modalShip_flex'>
                  <input style={{width: '150px', textAlign: "left"}} onChange={onChange1} 
                    onKeyDown={(e) => handleOnKeyPress1(e)} value={textShipNo || ''}></input>
                  {props.isRegister? 
                    <div className='modalShip_button2_1' onClick={idOverlapCheck}>중복확인</div>
                    : null}
                </div>
              </div>
              <div className='modalShip_item'>
                <div className='modalShip_item_left'>어선명</div>
                <input style={{width: '150px', textAlign: "left"}} onChange={onChange2} value={textShipName || ''}></input>
              </div>
              <div className='modalShip_item'>
                <div className='modalShip_item_left'>선적항</div>
                <input style={{width: '150px', textAlign: "left"}} onChange={onChange3} value={textPort || ''}></input>
              </div>
              <div className='modalShip_item'>
                <div className='modalShip_item_left'>게이트웨이 ID</div>
                <div className='modalShip_flex'>
                  <input style={{width: '150px', textAlign: "left"}} value={textGateway || ''} disabled={true}></input>
                  <div className='modalShip_search' onClick={()=>setModalGatewayOpen(true)}>
                    <BiSearch></BiSearch></div>
                </div>
              </div>
              <div style={{height:'15px'}}></div>
              <div className='modalShip_itemTitle'>
                <div className='modalShip_itemTitle_left'>선주정보</div>
                <div className='modalShip_search' onClick={()=>setModalUser1Open(true)}>
                    <BiSearch></BiSearch></div>
              </div>
              <div className='modalShip_line'></div>
              <div className='modalShip_item'>
                <div className='modalShip_item_left'>이름</div>
                <div className='modalShip_flex'>
                  <input style={{width: '150px', textAlign: "left"}} value={textOwnerName || ''} disabled={true}></input>
                </div>
              </div>
              <div className='modalShip_item'>
                <div className='modalShip_item_left'>전화번호</div>
                <input style={{width: '150px', textAlign: "left"}} value={textOwnerPhone || ''} disabled={true}></input>
              </div>
              <div className='modalShip_item'>
                <div className='modalShip_item_left'>주소</div>
                <input style={{width: '210px', textAlign: "left"}} value={textOwnerAddress || ''} disabled={true}/>
              </div>
              <div className='modalShip_item'>
                <div className='modalShip_item_left'>이메일</div>
                <input style={{width: '210px', textAlign: "left"}} value={textOwnerEmail || ''} disabled={true}></input>
              </div>
              <div style={{height:'15px'}}></div>
              <div className='modalShip_itemTitle'>
                <div className='modalShip_itemTitle_left'>선장정보</div>
                <div className='modalShip_search' onClick={()=>setModalUser2Open(true)}>
                    <BiSearch></BiSearch></div>
              </div>
              <div className='modalShip_line'></div>
              <div className='modalShip_item'>
                <div className='modalShip_item_left'>이름</div>
                <input style={{width: '150px', textAlign: "left"}} value={textCaptainName || ''} disabled={true}></input>
              </div>
              <div className='modalShip_item'>
                <div className='modalShip_item_left'>전화번호</div>
                <input style={{width: '150px', textAlign: "left"}} value={textCaptainPhone || ''} disabled={true}></input>
              </div>
              <div className='modalShip_item'>
                <div className='modalShip_item_left'>이메일</div>
                <input style={{width: '210px', textAlign: "left"}} value={textCaptainEmail || ''} disabled={true}></input>
              </div>

              <div style={{height:'15px'}}></div>
              <div className='modalShip_itemTitle'>
                <div className='modalShip_itemTitle_left1'>어업허가증 / 선적증서</div>
              </div>
              <div className='modalShip_line'></div>

              <div className='modalShip_blank_5' />
              <div style={{display: 'flex'}}>
                <div style={{width: "10px"}}></div> 
                <div><img id="img1" src={imgFile1 ? imgFile1 : shipLicense1? 'license/'+shipLicense1 : noImage} width="150px" height="115px" onClick={() => openLightbox(1)}/></div>
                <div style={{width: "15px"}}></div>
                <div><img id="img2" src={imgFile2 ? imgFile2 : shipLicense2? 'license/'+shipLicense2 : noImage} width="150px" height="115px" onClick={() => openLightbox(2)}/></div> 
              </div> 
              <div style={{display: 'flex'}}>
              <div style={{width: "50px"}}></div> 
              <label className="modalShip_button4" for="input-file1">파일선택</label>
              <input type="file" id="input-file1" onChange={hadleImageUploadButton1} style={{display:"none"}}/>
              <div style={{width: "90px"}}></div>
              <label className="modalShip_button4" for="input-file2">파일선택</label>
              <input type="file" id="input-file2" onChange={hadleImageUploadButton2} style={{display:"none"}}/>
              </div>
            </div>

            <div className='modalShip_right'>
              <div className='modalShip_itemTitle'>어망등록</div>
              <div className='modalShip_line_long'></div>

              <div className='modalShip_right_flex'>

                <div className='modalShip_right1'>
                  <div className='modalShip_blank_10' />
                  <div className='modalShip_itemTitle1'>{"등록된 어망"}</div>
                  <div className='modalShip_blank_10' />

                  <div className='modalShip_result_1'>
                    <div className='modalShip_button2' onClick={removeClicked}>삭제</div>
                    <div>{"Total     " + (list1.length) + "건"}</div>
                  </div>

                  <div className='modalShip_result_2'>
                    <div className='modalShip_listitemTitle'>
                      <div className='modalShip_listitemTitleSub1' onClick={selectAllClicked1}>선택</div>
                      <div className='modalShip_listitemTitleSub1_1'>No.</div>
                      <div className='modalShip_listitemTitleSub2'>ID</div>
                      <div className='modalShip_listitemTitleSub4'>메모</div>
                    </div>
                    <div className='modalShip_listitems'>
                        {list1.map((device)=>(
                          <div className='modalShip_listitem' key={device.devEui}>
                            <div className='modalShip_listitemSub1'>
                              {device.select?
                                <ImCheckboxChecked onClick={() => clickSelect1(device.devEui)} /> :
                                <ImCheckboxUnchecked onClick={() => clickSelect1(device.devEui)} />}</div>
                            <div className='modalShip_listitemSub1_1'>
                            <input style={{width: '30px', height: '20px', textAlign: "center"}} 
                              value={(device.no === -1)? '':String(device.no)} 
                              onChange={(e)=>nunmberChanged(device, e)}></input>
                              </div>
                            <div className='modalShip_listitemSub2'>{device.devEui.toUpperCase()}</div>
                            <div className='modalShip_listitemSub4'>{device.description}</div>
                          </div>                  
                        ))}
                      </div>
                  </div>              
                </div> 

                <div className='modalShip_right2'>
                  <div className='modalShip_blank_10' />
                  <div className='modalShip_itemTitle1'>{"등록되지 않은 전체 어망"}</div>
                  <div className='modalShip_blank_10' />

                  <div className='modalShip_result_1'>
                    <div className='modalShip_button2' onClick={registerClicked}>등록</div>
                    <div className='modalShip_result1_right'>
                      <div>어망 ID</div>
                      <div className='modalShip_Hblank_5' />
                      <div className='modalShip_searchBox'>
                        <input className='searchInput' onChange={onChange4}
                          onKeyDown={(e) => handleOnKeyPress2(e)} value={searchContent}></input>
                        <BiSearch className='modalShip_searchIcon' onClick={search} />
                      </div>
                    </div>                    
                  </div>
                    
                  <div className='modalShip_result_2'>
                    <div className='modalShip_listitemTitle'>
                      <div className='modalShip_listitemTitleSub1' onClick={selectAllClicked2}>선택</div>
                      <div className='modalShip_listitemTitleSub2'>ID</div>
                      <div className='modalShip_listitemTitleSub4'>메모</div>
                    </div>
                    <div className='modalShip_listitems'>
                      {list2.map((device)=>(
                        <div className='modalShip_listitem' key={device.devEui}>
                          <div className='modalShip_listitemSub1'>
                            {device.select?
                              <ImCheckboxChecked onClick={() => clickSelect2(device.devEui)} /> :
                              <ImCheckboxUnchecked onClick={() => clickSelect2(device.devEui)} />}</div>
                          <div className='modalShip_listitemSub2'>{device.devEui.toUpperCase()}</div>
                          <div className='modalShip_listitemSub4'>{device.description}</div>
                        </div>                  
                      ))}
                    </div>
                  </div>              
                </div>    
              </div>
            
              <div className='modalShip_blank_10' />
              <div className='modalShip_storeCancel'>
                <div className='modalShip_storeButton' onClick={okClicked}>{props.isRegister? "등록":"저장"}</div>
                <div className='modalShip_Hblank_20' />
                <div className='modalShip_storeButton' onClick={cancelClicked}>취소</div>
              </div>

            </div>
          </div>  
            <ModalGatewaySelect
                open={modalGatewayOpen} 
                close={() => setModalGatewayOpen(false)}
                setGateway={setGateway} />
            <ModalUserSelect
                open={modalUser1Open} 
                close={() => setModalUser1Open(false)}
                setUser={setOwner} />
            <ModalUserSelect
                open={modalUser2Open} 
                close={() => setModalUser2Open(false)}
                setUser={setCaptain} />
        </div>
      ) : null}
      {alertOpen && (
        <MyAlert content={alertContent} ok={()=>setAlertOpen(false)}/>
      )}
      {alertOpen1 && (
        <MyAlert content={alertContent} ok={()=>{setAlertOpen1(false); close();}}/>
      )}
      {isOpen && (        
        <Lightbox
          mainSrc={imgSrc1}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default ModalShip