import axios from 'axios';
import storage from '../../lib/storage';

export function commRegisterShipDB(shipData)
{
  axios({
    method: "POST",
    url: 'api/shipdb/register',
    data: shipData
  }).then(function(response) {
  }).catch(error=>{
    console.log(error);
    alert("선박 DB 등록 실패");
  });
}

export function commUpdateShipDB(updateData)
{
    axios({
        method: "POST",
        url: 'api/shipdb/update',
        data: updateData
      }).then(function(response) {
      }).catch(error=>{
        console.log(error);
        //alert(updateData.devEui + "선박 DB 업데이트 실패");
      });
}

export function commDeleteShipDB(shipNo)
{
  axios({
    method: "POST",
    url: 'api/shipdb/delete',
    data: {'shipNo': shipNo}
  }).then(function(response) {
  }).catch(error=>{
    console.log(error);
    alert("선박 DB 삭제 실패");
  });
}

export function commUpdateDeviceDB(updateData)
{
    axios({
        method: "POST",
        url: 'api/devicedb/update',
        data: updateData
      }).then(function(response) {
      }).catch(error=>{
        console.log(error);
        alert(updateData.devEui + " DB 업데이트 실패");
      });
}

export function commGetDeviceDB(devEui, shipNo)
{
  const reqUrl = '/api/devicedb/dev?' + devEui ? "&devEui="+devEui : "" + shipNo ? "&shipNo="+shipNo : "";
  axios({
    method: "GET",
    url: reqUrl
  })
  .then(function(response) {
    console.log(response);
    alert(response.data);
  }).catch(error=>{
    console.log(error);
    alert(error);
  });
}

export async function commRegisterDeviceDB(deviceData)
{
  let retValue;
  
  try {
  const response = await axios({
    method: "POST",
    url: 'api/devicedb/register',
    data: deviceData
  });  
  retValue = response.data;
  }
  catch(error){
    console.log(error);
    alert("어망 DB 등록 실패");
  }
  
  return retValue;
}

export function commDeleteDeviceDB(devEui)
{
  axios({
    method: "POST",
    url: 'api/devicedb/delete',
    data: {'devEui': devEui}
  }).then(function(response) {
  }).catch(error=>{
    console.log(error);
    alert("어망 DB 삭제 실패");
  });
}

export function commRegisterGatewayDB(gatewayData)
{
  axios({
    method: "POST",
    url: 'api/gatewaydb/register',
    data: gatewayData
  }).then(function(response) {
  }).catch(error=>{
    console.log(error);
    alert("게이트웨이 DB 등록 실패");
  });
}

export function commUpdateGatewayDB(updateData)
{
    axios({
        method: "POST",
        url: 'api/gatewaydb/update',
        data: updateData
      }).then(function(response) {
      }).catch(error=>{
        console.log(error);
        alert(updateData.gatewayID + " DB 업데이트 실패");
      });
}

export function commDeleteGatewayDB(gatewayID)
{
  axios({
    method: "POST",
    url: 'api/gatewaydb/delete',
    data: {'gatewayID': gatewayID}
  }).then(function(response) {
  }).catch(error=>{
    console.log(error);
    alert("게이트웨이 DB 삭제 실패");
  });
}

export function commUpdateUserDB(updateData)
{
    axios({
        method: "POST",
        url: 'api/auth/update',
        data: updateData
      }).then(function(response) {
      }).catch(error=>{
        console.log(error);
        alert(updateData.userid + "사용자 정보 DB 업데이트 실패");
      });
}

export function commUpdateUserPassword(updateData)
{
  axios({
    method: "POST",
    url: 'api/auth/cp',
    data: updateData
  }).then(function(response) {
  }).catch(error=>{
    console.log(error);
    alert("현재 비밀번호가 다릅니다.");
  });
}

export function commDeleteUserDB(userid)
{
    axios({
        method: "POST",
        url: 'api/auth/delete',
        data: {userid},
      }).then(function(response) {
      }).catch(error=>{
        console.log(error);
        alert(userid + " DB 업데이트 실패");
      });
}

export async function commUploadShipLicenseImage(shipNo, file, pos, filename)
{
  const formData = new FormData();
  formData.append('image', file);
  try {
    await axios.post('/api/shipdb/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data', 'shipNo': shipNo, 'imgpos': pos, 'filename': filename },
    });
    //alert('파일이 업로드되었습니다.');    
  } catch (error) {
    console.error(error);
  }
}

export async function commSaveDeviceSendDataLog(devEui, data, evt)
{
  const sendData = {devEui, data, evt};
  console.log(sendData);
  axios({
    method: "POST",
    url: 'api/devices/snddevlog',
    data: sendData,
  }).then(function(response) {
  }).catch(error=>{
    console.log(error);
    alert("발송 로그 DB 업데이트 실패");
  });
}

export async function commBackupDB()
{
  await axios({
    method: "GET",
    url: 'api/auth/backup',
    responseType: 'blob'
  })
  .then(function(response) {
    const filename = response.headers.get('content-disposition').split('filename=')[1];
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response.data);
    link.download = filename.replace(/\"/g, "");
    link.click();
  }).catch(error=>{
    console.log(error);
    alert(error);
  });
}

export async function commRestoreDB(file)
{
  const formData = new FormData();
  formData.append('file', file);
  var retVal;
  try {
    await axios.post('/api/auth/restore', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then(function(response) {
      retVal = response;
    });

    return retVal;
  } catch (error) {
    console.error(error);
  }
}

export async function commEtcDBUpdate(updateData)
{
  console.log(updateData);
  axios({
    method: "POST",
    url: 'api/etcdb/set',
    data: updateData,
  }).then(function(response) {
    alert("SMS 정보를 업데이트했습니다.");
  }).catch(error=>{
    console.log(error);
    alert("SMS DB 업데이트 실패");
  });
}